const CadenaComercialSelect = ({ value, onChange }) => {
  return (
    <div className="col-md-4 col-lg-3 mt-4">
      <div className="form-label-group w-100">
        <select
          id="cadenaComercial"
          value={value}
          onChange={onChange}
          className="form-control mb-rem5 mw-50"
        >
          <option value="1">Liverpool</option>
          <option value="2">Suburbia</option>
        </select>
      </div>
    </div>
  );
};

export default CadenaComercialSelect;
