import React, { useEffect, useMemo, useState } from "react";
import { fetchGlobalPifDataZonas } from "../../../hooks/useFetchPifData";
import DownloadButton from '../../../components/general/DownloadButton';
import CadenaComercialSelect from "../../../components/selects/CadenaComercialSelect";
import PifDivisionTable from '../Tables/PifDivisionTable';
import PifZonasTable from '../Tables/PifZonasTable';
import PifTiendaTable from '../Tables/PifTiendaTable';

const PifDataTable = (props) => {
  const {
    setloading,
    view,
    division,
    pifZona,
    zona,
    handlePifDivision,
    handlePifZona,
    numeroEmpleado,
    numeroTienda,
    usuario,
    setTitleFecha,
    mes,
    anio,
    meta,
  } = props;

  const [cadenaComercial, setCadenaComercial] = useState("1");
  const [avances, setavances] = useState(null);
  const [mesActual, setMesActual] = useState(null);
  const [subTotal, setSubtotal] = useState(null);

  useEffect(() => {
    setMesActual(new Date().getMonth() + 1);

    fetchGlobalPifDataZonas({
      view,
      cadenaComercial,
      zona,
      division,
      pifZona,
      numeroTienda,
      numeroEmpleado,
      mes,
      anio,
      meta,
      setloading,
      setavances,
      setSubtotal,
      setTitleFecha,
    });
  }, [view, cadenaComercial, mes, anio, meta, division, pifZona]);

  const data = useMemo(() => avances, [avances]);

  if (!data || data.length === 0) return <Warning />;

  const renderTable = () => {
    const commonProps = {
      data,
      cadenaComercial,
      mes,
      mesActual,
    };

    switch (view) {
      // Por División
      case 1:
        return (
          <>
            <DownloadButton id="ranking-divisiones-pif" buttonText="Descargar divisiones de PIF" />
            <PifDivisionTable {...commonProps} handlePifDivision={handlePifDivision}/>
          </>
        );
      // Por Zona
      case 2:
        return (
          <>
            <DownloadButton id="ranking-zonas-pif" buttonText="Descargar zonas de PIF" />
            <PifZonasTable {...commonProps} handlePifZona={handlePifZona} usuario={usuario}/>
          </>
        );
      // Por Tienda
      case 3:
        return (
          <> 
            <DownloadButton id="ranking-tienda-pif" buttonText="Descargar tiendas de PIF" />
            <PifTiendaTable {...commonProps} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {view === 1 && (
        <CadenaComercialSelect
          value={cadenaComercial}
          onChange={(e) => setCadenaComercial(e.target.value)}
        />
      )}
      {renderTable()}
    </div>
  );
};

export const Warning = () => {
  return (
    <div className="text-center alert alert-warning my-1rem" role="alert">
      Aún no hay avances PIF aquí.
    </div>
  );
};

export default PifDataTable;
