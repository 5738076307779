/* eslint-disable jsx-a11y/anchor-is-valid */
import swal from "@sweetalert/with-react";
import React, { useEffect, useState } from "react";
// Bootstrap Components
import Container from "react-bootstrap/Container";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
//Libraries
import { ToastContainer } from "react-toastify";
// Components
import Header from "./components/layout/Header";
import Navbar from "./components/layout/Navbar";
import instance from "./helpers/api.instance";
import Estadisticas from "./pages/Avances/Estadisticas";
import CargaArchivos from "./pages/Carga/CargaArchivos";
import Dashboard from "./pages/Home/Dashborad";
import Home from "./pages/Home/Home";
// Pages
import Login from "./pages/Login";
import Medallero from "./pages/medallero/Medallero";
import AdministracionMetas from "./pages/Metas/AdministracionMetas";
import AnadirUsuario from "./pages/Usuarios/AnadirUsuario";
import ListaUsuarios from "./pages/Usuarios/ListaUsuarios";
import Perfil from "./pages/Usuarios/Perfil";
import EditarMedallero from "./pages/medallero/EditarMedallero";
import IndexBuscar from "./pages/Buscar/indexBuscar";
import PorAsesor from "./pages/Performance/PorAsesor";
import AsesoresEnCero from "./pages/AsesoresEnCero/Graficas";
import MonederosEden from "./pages/Monederos/Edenred";

function App() {
  // Hook donde se guarda toda la info del usuario
  const [userInfo, setuserInfo] = useState(null);
  // Hook que ayuda a saber si el usuario está loggeado o no
  const [logged, setlogged] = useState(false);
  // Loader
  const [loading, setloading] = useState(false);
  // Objeto de usuario en el que se podrá verificar todos los permisos
  const [usuario, setusuario] = useState(null);
  // Hook que determina si el usuario debe de cambiar su contraseña o no
  const [reciente, setreciente] = useState("0");

  const AgregarTelefono = () => {
    swal(
      "Antes de continuar, es necesario que nos poporciones tu número de celular a diez dígitos. Así te podremos mantener actualizado con tu progreso.",
      {
        closeOnEsc: false,
        closeOnClickOutside: false,
        content: "input",
        button: {
          text: "Actualizar",
          closeModal: false,
        },
        icon: "info",
      }
    ).then((telefono) => {
      if (!isNaN(telefono) && telefono.length === 10) {
        return instance
          .post(`usuario/actualizaTelefono`, {
            idUsuario: userInfo.idUsuario,
            telefono: telefono,
            attributes: {
              placeholder: "Ingresa tu número a 10 dígitos",
              type: "tel",
            },
          })
          .then((res) => {
            if (res.status === 1 || reciente === "1") cambioDeContrasena();
            if (res.status === 0) AgregarTelefono();
          });
      } else {
        AgregarTelefono();
      }
    });
  };

  const cambioDeContrasena = () => {
    return swal(
      "Por favor, elige una nueva contraseña. Esto ayudará a evitar que alguien más pueda entrar a tu cuenta.",
      {
        closeOnEsc: false,
        closeOnClickOutside: false,
        content: "input",
        button: {
          text: "Actualizar",
          closeModal: false,
        },
        icon: "info",
      }
    ).then((contrasena) => {
      if (contrasena !== "") {
        return instance
          .post(`usuario/actualizaPassword`, {
            idUsuario: userInfo.idUsuario,
            password: contrasena,
          })
          .then((res) => {
            if (res.status === 1) {
              return swal("¡Datos actualizados correctamente!", {
                icon: "success",
              });
            } else {
              return cambioDeContrasena();
            }
          });
      } else {
        cambioDeContrasena();
      }
    });
  };

  // useEffect que determina si el usuario debe de cambiar su contraseña o no
  useEffect(() => {
    if (reciente === "1") cambioDeContrasena();
    if (userInfo) {
      if (!userInfo.celular || userInfo.celular === "") {
        AgregarTelefono();
      }
    }
  }, [reciente, userInfo?.idUsuario, userInfo?.celular]);

  return (
    <Router>
      {/* Loader que se activa al cargar algo, modificando la propiedad setloading() */}
      <div
        className={`loaderContainer w-100 justify-content-center ${
          loading ? "" : "d-none"
        } `}
      >
        <div className="loader"></div>
      </div>

      {/* Toast conatiner para las notificaciones */}
      <ToastContainer />
      {/* Toast conatiner para las notificaciones */}

      <Header
        logged={logged}
        setlogged={setlogged}
        usuario={usuario}
        userInfo={userInfo}
      />

      <div className="d-flex fixedContent">
        <Navbar logged={logged} usuario={usuario} userInfo={userInfo} />

        {/* Main Content */}
        <Container fluid className="mainContent">
          {/* Routing */}
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`}>
              {logged ? (
                <Dashboard
                  userInfo={userInfo}
                  setloading={setloading}
                  usuario={usuario}
                />
              ) : (
                <Home />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/login`}>
              <Login
                logged={logged}
                setlogged={setlogged}
                setuserInfo={setuserInfo}
                loading={loading}
                setloading={setloading}
                setusuario={setusuario}
                setreciente={setreciente}
              />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/home`}>
              {logged ? (
                usuario ? (
                  <Dashboard
                    userInfo={userInfo}
                    setloading={setloading}
                    usuario={usuario}
                  />
                ) : null
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/usuarios`}>
              {logged ? (
                <ListaUsuarios
                  userInfo={userInfo}
                  setloading={setloading}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/anadir-usuario`}>
              {logged ? (
                usuario ? (
                  <AnadirUsuario
                    userInfo={userInfo}
                    setloading={setloading}
                    usuario={usuario}
                  />
                ) : null
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/carga-de-metas`}>
              {logged ? (
                <CargaArchivos setloading={setloading} />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/administracion-de-metas`}>
              {logged ? (
                <AdministracionMetas
                  setloading={setloading}
                  userInfo={userInfo}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/estadisticas`}>
              {logged ? (
                <Estadisticas
                  userInfo={userInfo}
                  setloading={setloading}
                  loading={loading}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/perfil`}>
              {logged ? (
                <Perfil userInfo={userInfo} setloading={setloading} />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/medallero`}>
              {logged ? (
                <Medallero
                  userInfo={userInfo}
                  setloading={setloading}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/editar-medallero`}>
              {logged ? (
                <EditarMedallero setloading={setloading} userInfo={userInfo} />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/buscar`}>
              {logged ? (
                <IndexBuscar
                  userInfo={userInfo}
                  setloading={setloading}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/table-all`}>
              <IndexBuscar
                userInfo={userInfo}
                setloading={setloading}
                usuario={usuario}
              />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/performance`}>
              {logged ? (
                <PorAsesor
                  setloading={setloading}
                  userInfo={userInfo}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/asesores-en-cero`}>
              {logged ? (
                <AsesoresEnCero
                  setloading={setloading}
                  userInfo={userInfo}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/monederos-edenred`}>
              {logged ? (
                <MonederosEden
                  setloading={setloading}
                  userInfo={userInfo}
                  usuario={usuario}
                />
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Route>

            {/* Redirect all 404's to home */}
            <Redirect to={`${process.env.PUBLIC_URL}/`} />
          </Switch>
          {/* Routing */}
        </Container>
        {/* END OF: Main Content */}
      </div>
    </Router>
  );
}

export default App;
