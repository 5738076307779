import React, { useState, useEffect } from "react";
// Libraries
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import instance from "../../helpers/api.instance";
import swal from "@sweetalert/with-react";
// Components
import AlertForm from "../../components/general/AlertForm";
// Helpers
import asignarPermisos from "../../helpers/permissions";
// Bootstrap Components
import Button from "react-bootstrap/Button";
// ICONS
import IconProfile from "../../assets/icons/profile_black.svg";
import IconBack from "../../assets/icons/back.svg";

const AnadirUsuario = ({ userInfo, setloading, usuario }) => {
  // React Form Hook
  const { register, handleSubmit, errors } = useForm();
  // Perfiles que se guardarán en el hook
  const [perfiles, setperfiles] = useState(null);
  const [usuarioACrear, setusuarioACrear] = useState(
    usuario.tipo === 1
      ? asignarPermisos("Administrador_PIF")
      : asignarPermisos("Asesor_tienda_liverpool")
  );
  // Form hooks
  const [perfilSeleccionado, setperfilSeleccionado] = useState(null);
  const [cadenacomercial, setcadenacomercial] = useState(
    userInfo.cadenaComercial ? userInfo.cadenaComercial : "1"
  );
  const [tiendas, settiendas] = useState(null);
  // Hook para guardar las zonas (en caso de un coordinador de zonas)
  const [zonas, setzonas] = useState(null);
  // Estado para el toggle del monedero
  const [tieneMonedero, setTieneMonedero] = useState(false);
  // History to use
  let history = useHistory();

  // Estilos en línea para el toggle switch
  const switchStyles = {
    position: "relative",
    display: "inline-block",
    width: "50px",
    height: "24px",
  };

  const inputStyles = {
    opacity: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    cursor: "pointer",
    margin: 0,
  };

  const sliderStyles = (checked) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: checked ? "#0275d8" : "#ccc",
    transition: "0.4s",
    borderRadius: "24px",
    pointerEvents: "none",
  });

  const circleStyles = (checked) => ({
    position: "absolute",
    height: "18px",
    width: "18px",
    left: checked ? "26px" : "3px",
    bottom: "3px",
    backgroundColor: "white",
    transition: "0.4s",
    borderRadius: "50%",
    pointerEvents: "none",
  });

  // Hook para obtener el listado de perfiles de una tienda
  useEffect(() => {
    setloading(true);
    if (usuario.tipo === 1) {
      // Obtenemos el listado de perfiles
      instance
        .get(`/usuario/listadoPerfiles/${cadenacomercial}`)
        .then((res) => {
          // Guardamos los perfiles en el hook
          setperfiles(res.data.perfiles);
        })
        // Desactivamos el loader una vez hecho el request
        .finally(() => setloading(false));
    } else if (usuario.tipo === 2) {
      // Obtenemos el listado de perfiles
      instance
        .post(
          `/usuario/perfilesJefeCredito`, //Cuerpo del request
          {
            cadenaComercial: userInfo.cadenaComercial,
          }
        )
        .then((res) => {
          // Guardamos los perfiles en el hook
          setperfiles(res.data.perfiles);
        })
        // Desactivamos el loader una vez hecho el request
        .finally(() => setloading(false));
    }
  }, [cadenacomercial, userInfo]);

  // Hook para obtener la lista de tiendas de una cadena comercial
  useEffect(() => {
    if (cadenacomercial !== null) {
      setloading(true);
      instance
        .get(`/tiendas/listaTiendas/${cadenacomercial}`)
        .then((response) => {
          settiendas(response.data.tiendas);
        })
        .finally(() => setloading(false));
    }
  }, [cadenacomercial]);

  useEffect(() => {
    if (usuarioACrear && usuarioACrear.tiene.zona) {
      setloading(true);
      instance
        .get(`tiendas/catalogoZonas/${cadenacomercial}`)
        .then((res) => setzonas(res.data.zonas))
        .finally(() => setloading(false));
    }
  }, [usuarioACrear]);

  const onSubmit = (data) => {
    // Cada nuevo usuario requiere saber quién lo añadió, así que lo añadimos a la data para enviar
    data.usuarioRegistra = userInfo.idUsuario;
    // Enviamos la cadena comercial a la que pertenece este usuario
    data.cadenaComercial = cadenacomercial;

    // Si el perfil es de tipo 2 (jefe de crédito) se asigna la cadena comercial y número de tienda propios de quien lo crea
    if (usuario.tipo === 2) data.numeroTienda = userInfo.numeroTienda;
    if (userInfo.tipo === 2) data.cadenaComercial = userInfo.cadenaComercial;

    if (usuarioACrear.esAdmin) data.numeroEmpleado = "GP" + data.numeroEmpleado;

    setloading(true);
    // Enviamos el usuario a la API
    instance
      .post(
        "/usuario/registrar",
        //Cuerpo del request
        data
      )
      .then((res) => {
        console.log('Respuesta:', res);
        if (res.status === 1)
          swal({
            title: "Usuario añadido",
            closeOnEsc: false,
            closeOnClickOutside: false,
            content: (
              <div>
                <div className="row justify-content-between">
                  <div className="col text-left">Número de empleado:</div>
                  <div className="col text-right">{data.numeroEmpleado} </div>
                </div>
                <div className="row justify-content-between mt-1rem">
                  <div className="col text-left">Contraseña:</div>
                  <div className="col text-right">{res.data.password} </div>
                </div>

                <hr />

                <div className="row text-center mt-1rem">
                  <div className="col w-100">
                    <p>
                      Recuerda que esta información es sensible, guárdala en un
                      lugar seguro.
                    </p>
                    <p>
                      Se le pedirá al usuario que cambie su contraseña la
                      primera vez que ingrese.
                    </p>
                  </div>
                </div>
              </div>
            ),
            icon: "success",
          });
      })
      // Apagamos el loader
      .finally(() => setloading(false));
  };

  // Cambia el perfil seleccionado y le asigna permisos
  const handleUsuario = (usuario) => {
    console.log(usuario)
    setperfilSeleccionado(usuario);
    setusuarioACrear(asignarPermisos(usuario));
  };

  if (!perfiles) return null;

  return (
    <div className="card rounded">
      <div onClick={() => history.goBack()} className="cursor-pointer">
        <img src={IconBack} alt="Back Arrow" className="back-arrow" />
      </div>
      <div className="text-center">
        <img
          src={IconProfile}
          className="img-fluid"
          width="75"
          alt="Icon Profile"
        />
      </div>
      <div className="text-center pt-1rem mb-3rem">
        <h2>Alta de usuario</h2>
      </div>
      <div className="row justify-content-center">
        {/* Formulario */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-50 justify-content-center text-center"
        >
          {/* Cadena comercial */}
          {usuario.tipo === 1
            ? usuarioACrear.tiene.cadenaComercial && (
                <div className="row form-label-group">
                  <div className="col-6">
                    <label htmlFor="cadenaComercial">Línea de negocio*:</label>
                  </div>
                  <div className="col-6">
                    <select
                      name="cadenaComercial"
                      id="cadenaComercial"
                      className="form-control mb-rem5"
                      ref={register({ required: true })}
                      onChange={(event) => {
                        setcadenacomercial(event.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="1">Liverpool</option>
                      <option value="2">Suburbia</option>
                    </select>
                    {errors.cadenaComercial && <AlertForm />}
                  </div>
                </div>
              )
            : null}
          {/* END OF: Cadena comercial */}

          {/* Tipo de usuario */}
          <div className="row form-label-group">
            <div className="col-6">
              <label htmlFor="clavePerfil">Tipo de usuario*:</label>
            </div>
            <div className="col-6">
              <select
                name="clavePerfil"
                className="form-control mb-rem5"
                ref={register({ required: true })}
                onChange={(e) => {
                  handleUsuario(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {perfiles.map((perfil) => {
                  return (
                    <option value={perfil.clavePerfil} key={perfil.id}>
                      {perfil.nombrePerfil}
                    </option>
                  );
                })}
              </select>
              {errors.clavePerfil && <AlertForm />}
            </div>
          </div>
          {/* end of: tipo de usuario */}

          {zonas
            ? usuarioACrear.tiene.zonas && (
                <div className="row form-label-group">
                  <div className="col-6">
                    <label htmlFor="Zonas">Zonas*:</label>
                  </div>
                  <div className="col-6">
                    <select
                      name="zonas"
                      id="zonas"
                      className="form-control mb-rem5"
                      ref={register({ required: true })}
                      multiple={true}
                      data-style="bg-white rounded-pill px-4 py-3 shadow-sm "
                    >
                      <option value="">Selecciona</option>
                      {tiendas
                        ? zonas.map((zona) => {
                            return (
                              <option value={zona.zona} key={zona.zona}>
                                {zona.zona}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {errors.tienda && <AlertForm />}
                  </div>
                </div>
              )
            : null}

          {/* Tienda */}
          {usuario.tipo === 1
            ? usuarioACrear.tiene.tienda && (
                <div className="row form-label-group">
                  <div className="col-6">
                    <label htmlFor="numeroTienda">Tienda*:</label>
                  </div>
                  <div className="col-6">
                    <select
                      name="numeroTienda"
                      id="numeroTienda"
                      className="form-control mb-rem5"
                      ref={register({ required: true })}
                    >
                      <option value="">Selecciona</option>
                      {tiendas
                        ? tiendas.map((tienda) => {
                            return (
                              <option
                                value={tienda.numeroTienda}
                                key={tienda.id}
                              >
                                {tienda.numeroTienda} - {tienda.nombreTienda}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {errors.tienda && <AlertForm />}
                  </div>
                </div>
              )
            : null}
          {/* END OF: Tienda */}

          {/* Correo */}
          {usuarioACrear.tiene.correo && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="correo">Correo*:</label>
              </div>
              <div className="col-6">
                <input
                  type="email"
                  name="correo"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.correo && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: Correo */}

          {/* Celular */}
          {/* Todo: añadir validaciones de longitud, tipo, etc. a todos los campos */}
          {usuarioACrear.tiene.celular && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="celular">Celular*:</label>
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="celular"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.celular && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: Celular */}

          {/* No. Empleado */}
          {usuario.tiene.numeroEmpleado && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="numeroEmpleado">No. de empleado*:</label>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  name="numeroEmpleado"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.numeroEmpleado && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: No. Empleado */}

          {/* Nombre */}
          {usuarioACrear.tiene.nombres && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="nombres">Nombre(s)*:</label>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  name="nombres"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.nombres && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: Nombre */}

          {/* Apellido paterno */}
          {usuarioACrear.tiene.apPat && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="apPat">Apellido paterno*:</label>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  name="apPat"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.apPat && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: Apellido paterno */}

          {/* Apellido materno */}
          {usuarioACrear.tiene.apMat && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="apMat">Apellido materno*:</label>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  name="apMat"
                  id="apMat"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.apMat && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: Apellido materno */}

          {/* Género */}
          {usuarioACrear.tiene.genero && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="genero">Género:</label>
              </div>
              <div className="col-6">
                <select
                  name="genero"
                  className="form-control mb-rem5"
                  ref={register({ required: false })}
                >
                  <option value="">Selecciona</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </select>
                {errors.genero && <AlertForm />}
              </div>
            </div>
          )}
          {/* END OF: Género */}

          {/* Meta Asignada */}
          {/* {perfilSeleccionado === 'Asesor_tienda_liverpool' && (
            <div className="row form-label-group">
              <div className="col-6">
                <label htmlFor="reactivacionesNuevoIngreso">Meta asignada*:</label>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  name="reactivacionesNuevoIngreso"
                  className="form-control mb-rem5"
                  ref={register({ required: true })}
                />
                {errors.reactivacionesNuevoIngreso && <AlertForm />}
              </div>
            </div>
          )} */}
          {/* END OF: Meta Asignada */}

          {/* Monedero Edenred Input */}
          {perfilSeleccionado === 'Asesor_tienda_liverpool' && (
            <>
              <div className="row form-label-group">
                <div className="col-6">
                  <label>Tiene Monedero Edenred:</label>
                </div>
                <div className="col-6">
                  <div style={switchStyles}>
                    <input
                      type="checkbox"
                      checked={tieneMonedero}
                      onChange={() => {
                        setTieneMonedero(!tieneMonedero);
                      }}
                      style={inputStyles}
                    />
                    <div style={sliderStyles(tieneMonedero)}>
                      <div style={circleStyles(tieneMonedero)}></div>
                    </div>
                  </div>
                </div>
              </div>
              {tieneMonedero && (
                <div className="row form-label-group">
                  <div className="col-6">
                    <label htmlFor="monedero">Número de Monedero*:</label>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      name="monedero"
                      className="form-control mb-rem5"
                      ref={register({
                        validate: (value) => {
                          if (/^\d{16}$/.test(value)) {
                            return true;
                          } else {
                            return "El monedero debe ser 16 dígitos numéricos";
                          }
                        },
                      })}
                    />
                    {errors.monedero && <AlertForm texto="El monedero debe ser 16 dígitos numéricos." />}
                  </div>
                </div>
              )}
            </>
          )}

          <Button variant="primary" type="submit" className="ml-3rem mt-1rem5">
            Dar de alta
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AnadirUsuario;
