import React, { useState, useEffect } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import swal from '@sweetalert/with-react';
import instance from "../../helpers/api.instance";
import { notification } from "../../helpers/Helpers";
import formato from '../../assets/files/formatodeaclaracion.docx'

const Edenred = ({ setloading, userInfo, usuario }) => {
  // Estado para los filtros
  const [cadenaComercial, setCadenaComercial] = useState(
    userInfo.cadenaComercial ? userInfo.cadenaComercial.toString() : '1'
  );

  const [numeroTienda, setNumeroTienda] = useState(
    userInfo.numeroTienda ? userInfo.numeroTienda.toString() : '1'
  );

  const [data, setData] = useState([]);
  const [canModify, setCanModify] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCadenaComercial = (cadena) => {
    setCadenaComercial(cadena);
  };

  const handleNumeroTienda = (e) => {
    const value = e.target.value;
    const re = /^[0-9]*$/;
    if (value === '' || re.test(value)) {
      setNumeroTienda(value);
    }
  };

  const fetchData = async () => {
    if (numeroTienda.trim() === '') {
      setData([]);
      setCanModify(false);
      return;
    }
    setLoading(true);
    try {
      const params = {
        cadena_comercial: cadenaComercial,
        numero_tienda: numeroTienda,
      };

      const response = await instance.get('/monederos', { params });

      if (response.data && response.data.length > 0) {
        setData(response.data);
        setCanModify(Boolean(response.can_modify));
        notification('¡Se encontraron resultados!', 'success');
      } else {
        setData([]);
        notification('No se encontraron datos', 'warning');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      notification('Error al obtener los datos.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // useEffect para actualizar los datos cuando cambien los filtros
  useEffect(() => {
    fetchData();
  }, [cadenaComercial, numeroTienda]);

  const handleEstatusChange = async (numeroEmpleado, nuevoEstatus) => {
    let confirmationMessage = '';
  
    switch (nuevoEstatus) {
      case 'correcto':
        confirmationMessage = '¿Está seguro de que desea marcar como correcto este registro?';
        break;
      case 'extravio':
        confirmationMessage =
          'Al marcar como extravío, se asignará un nuevo monedero al colaborador.';
        break;
      case 'baja/incapacidad':
        confirmationMessage =
          'Al marcar como baja o incapacidad, no se asignará un nuevo monedero.';
        break;
      default:
        return;
    }
  
    swal({
      title: 'Confirmación',
      text: confirmationMessage,
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true,
    }).then(async (willProceed) => {
      if (willProceed) {
        setLoading(true);
        try {
          const requestBody = {
            numeroEmpleado,
            estatus: nuevoEstatus,
          };
  
          const response = await instance.post('/monederos/valida_informacion', requestBody);
  
          if (response.status === true) {
            await fetchData();
            notification(response.message, 'success');
          } else {
            notification(response.message || 'No se pudo cambiar el estatus', 'error');
          }
        } catch (error) {
          console.error('Error changing estatus:', error);
          notification('Ocurrió un error al actualizar el estatus.', 'error');
        } finally {
          setLoading(false);
        }
      }
    });
  };  

  return (
    <>
      <div className="row flex-column mt-n1rem5 gap-3">
        <div className="flex-wrap text-center justify-content-center row my-3rem">
          <div className="col col-lg-3">
            <label htmlFor="cadenaComercial">Línea de negocio:</label>
            <div className="form-label-group w-100">
              <select
                name="Categoria"
                id="categoria"
                className="form-control mb-rem5 mw-50"
                onChange={(e) => handleCadenaComercial(e.target.value)}
                value={cadenaComercial}
              >
                <option value="1">Liverpool</option>
                <option value="2">Suburbia</option>
              </select>
            </div>
          </div>

          <div className="col col-lg-3">
            <label htmlFor="numeroTienda">Número de Tienda:</label>
            <div className="form-label-group w-100">
              <input
                type="text"
                name="numeroTienda"
                id="numeroTienda"
                className="form-control mb-rem5 mw-50"
                onChange={handleNumeroTienda}
                value={numeroTienda}
                placeholder="Ingrese número de tienda"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 mt-1rem5">
          <div className="rounded card p-3 mb-5" style={{ overflowX: 'auto' }}>
            {/* Indicador de carga */}
            {loading && (
              <div className="text-center my-3">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {/* Tabla de datos */}
            {!loading && (
              <Table id="edenred-rank" className="table table-hover table-sm">
                <thead>
                  <tr>
                    <th className="align-middle table-dark">ID Tienda</th>
                    <th className="align-middle table-dark">Nombre Tienda</th>
                    <th className="align-middle table-dark"># Empleado</th>
                    <th className="align-middle table-dark">Asesor</th>
                    <th className="align-middle table-dark">Área</th>
                    <th className="align-middle table-dark">Número de Monedero</th>
                    <th className="align-middle table-dark">Es correcto</th>
                    <th className="align-middle table-dark">Marcar en caso de extravío</th>
                    <th className="align-middle table-dark">Marcar en caso de baja o incapacidad</th>
                  </tr>
                </thead>
                <tbody className="table-bordered">
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.numero}>
                        <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.tienda?.id ?? 'N/A'}</td>
                        <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.tienda?.nombre ?? 'N/A'}</td>
                        <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.numero ?? 'N/A'}</td>
                        <td className="align-middle" style={{ verticalAlign: 'middle' }}>
                          {item.nombre || item.apaterno || item.amaterno
                            ? `${item.nombre || ''} ${item.apaterno || ''} ${item.amaterno || ''}`.trim()
                            : 'N/A'}
                        </td>
                        <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.area ?? 'N/A'}</td>
                        <td className="align-middle" style={{ verticalAlign: 'middle' }}>{item.monedero ?? 'N/A'}</td>
                        <td
                          className="align-middle text-center"
                          onClick={() => {
                            if (canModify && item.estatus !== 'correcto') {
                              handleEstatusChange(item.numero, 'correcto');
                            }
                          }}
                          style={{
                            verticalAlign: 'middle',
                            cursor: canModify && item.estatus !== 'correcto' ? 'pointer' : 'default',
                            backgroundColor: item.estatus === 'correcto' ? '#d4edda' : '',
                          }}
                        >
                          {item.estatus === 'correcto' ? '✓' : ''}
                        </td>
                        {/* Marcar en caso de extravío */}
                        <td
                          className="align-middle text-center"
                          onClick={() => {
                            if (canModify && item.estatus !== 'extravio') {
                              handleEstatusChange(item.numero, 'extravio');
                            }
                          }}
                          style={{
                            verticalAlign: 'middle',
                            cursor: canModify && item.estatus !== 'extravio' ? 'pointer' : 'default',
                            backgroundColor: item.estatus === 'extravio' ? '#fff3cd' : '',
                          }}
                        >
                          {item.estatus === 'extravio' ? '✓' : ''}
                        </td>
                        {/* Marcar en caso de baja o incapacidad */}
                        <td
                          className="align-middle text-center"
                          onClick={() => {
                            if (canModify && item.estatus !== 'baja/incapacidad') {
                              handleEstatusChange(item.numero, 'baja/incapacidad');
                            }
                          }}
                          style={{
                            verticalAlign: 'middle',
                            cursor: canModify && item.estatus !== 'baja/incapacidad' ? 'pointer' : 'default',
                            backgroundColor: item.estatus === 'baja/incapacidad' ? '#f8d7da' : '',
                          }}
                        >
                          {item.estatus === 'baja/incapacidad' ? '✓' : ''}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No hay datos disponibles
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}

            {/* Botón para descargar el archivo y texto adicional */}
            <div className="mt-3 text-left">
              <a href={formato} download className="btn btn-primary">
                Descargar Formato
              </a>
              <p className="mt-2">
                Este formato se deberá enviar por correo a <strong>atencionpif@centrodeseguros.com.mx</strong> | <strong>ghernandez@gpmass.com</strong> | <strong>jaromerow@liverpool.com.mx</strong>
                <br />
                Con Asunto: <strong>Aclaración monedero nombre | tienda</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edenred;
