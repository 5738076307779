import instance from "../helpers/api.instance";

export const fetchGlobalPifDataZonas = async ({
  setloading,
  view,
  zona,
  division,
  pifZona,
  numeroEmpleado,
  numeroTienda,
  cadenaComercial,
  mes,
  anio,
  meta,
  setavances,
  setSubtotal,
  setTitleFecha,
  setAvanceIdeal,
}) => {
  setloading(true);
  setavances(null);

  if (mes && anio) {
    try {
      let response = null;
      switch (view) {
        case 1:
          response = await instance.get(`/pif-nomina/avances/divisiones?month=${mes}&year=${anio}`);
          break;
        case 2:
          response = await instance.get(`/pif-nomina/avances/zonas?month=${mes}&year=${anio}&division=${encodeURIComponent(division)}`);
          break;
        case 3:
          response = await instance.get(`/pif-nomina/avances/tiendas?month=${mes}&year=${anio}&division=${encodeURIComponent(division)}&zona=${encodeURIComponent(pifZona)}`);
          break;
        default:
          break;
      }

      if (response && response.data) {
        const cadena = cadenaComercial === "1" ? 'liverpool' : 'suburbia';
        const data = response.data[cadena]?.avances || [];
        setavances(data);
        setTitleFecha(response.data[cadena]?.fecha_ultima_actualizacion || "No disponible");
      } else {
        setavances([]);
      }
    } catch (error) {
      console.error("Error fetching PIF data:", error);
    } finally {
      setloading(false);
    }
  }
};
