const PifTiendaTable = ({ data }) => {
  return (
    <table id="ranking-tienda-pif" className="table table-striped mt-2">
      <thead>
        <tr>
          <th rowSpan='2' className="align-middle table-dark">Numero</th>
          <th rowSpan='2' className="align-middle table-dark">Nombre</th>
          <th colSpan="4" className="even-title">Cuentas Nuevas</th>
          <th colSpan="4" className="table-dark">Referidos</th>
        </tr>
        <tr>
          <th className="align-middle sticky-header even">Básico</th>
          <th className="align-middle sticky-header even">Plus</th>
          <th className="align-middle sticky-header even">Superior</th>
          <th className="align-middle sticky-header even">Total</th>
          <th className="align-middle sticky-header even">Básico</th>
          <th className="align-middle sticky-header even">Plus</th>
          <th className="align-middle sticky-header even">Superior</th>
          <th className="align-middle sticky-header even">Total</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map((row, index) => (
          <tr key={index}>
            <td>{row.numero}</td>
            <td>{row.nombre}</td>
            <td>{row.cuentas_nuevas.pif_basico}</td>
            <td>{row.cuentas_nuevas.pif_plus}</td>
            <td>{row.cuentas_nuevas.pif_superior}</td>
            <td>{row.cuentas_nuevas.total}</td>
            <td>{row.referidos.pif_basico}</td>
            <td>{row.referidos.pif_plus}</td>
            <td>{row.referidos.pif_superior}</td>
            <td>{row.referidos.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PifTiendaTable;
