// Libraries
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../helpers/api.instance";
import { Line } from "react-chartjs-2";
import { DateTime } from "luxon";
// Components
import Circulos from "../Avances/Componentes/CirculosVerticales";
// Bootstrap Components
import Container from "react-bootstrap/Container";
// Icons
import IconGold from "../../assets/icons/stars/gold.svg";
import IconSilver from "../../assets/icons/stars/silver.svg";
import IconSilverOutlined from "../../assets/icons/stars/silver_outlined.svg";
import IconBronze from "../../assets/icons/stars/bronze.svg";
// Easter egg
import party from "party-js";

const Dashborad = ({ userInfo, setloading, usuario }) => {
  // Aquí se gaurdarán los datos de las estadísticas
  const [data, setdata] = useState(null);
  // Variable para obtener la fecha local
  const [date, setDate] = useState(DateTime.now());
  const [mes, setmes] = useState(null);
  const [anio, setanio] = useState(null);
  // UseEffect principal que trae la info de un jefe de crédito
  useEffect(() => {
    if (userInfo && usuario && usuario.tiene.dashboard)
      instance
        .post(
          `/avances/estadistica${
            usuario.tipo === 2 ? `JefeCredito` : `Asesor`
          }`,
          // Cuerpo del request
          {
            cadenaComercial: userInfo.cadenaComercial,
            numeroTienda: userInfo.numeroTienda,
            numeroEmpleado: userInfo.numeroEmpleado,
          }
        )
        .then((res) => {
          console.log(`Respuesta inicial: ${JSON.stringify(res)}`)
          if (res.status === 1) setdata(res.data);
          if (res.status === 0) setdata(false);
        });
  }, [userInfo, usuario]);

  useEffect(() => {
    setmes(date.c.month);
    setanio(date.c.year);
  }, [date]);

  if (!userInfo || !usuario) return null;

  // Administrador
  if (usuario.tipo === 1 || usuario.tipo === 4 || usuario.tipo === 5)
    return (
      <div className="rounded card">
        <h3>{`Hola, ${userInfo.nombres}`}</h3>
        <h4>¿Qué deseas ver?</h4>
        <div className="row justify-content-between py-5rem">
          {usuario.puede.entrarA.estadisticas && (
            <div className="text-center col py-1rem">
              <Link to={`${process.env.PUBLIC_URL}/estadisticas`}>
                <button className="btn btn-primary">Estadísticas</button>
              </Link>{" "}
            </div>
          )}
          {/* {(usuario.tipo === 5 || usuario.puede.ver.usuarios.css) && (
            <>
              <div className="text-center col py-1rem">
                <Link to={`${process.env.PUBLIC_URL}/performance`}>
                  <button className="btn btn-primary">
                    Performance por asesor
                  </button>
                </Link>{" "}
              </div>
              <div className="text-center col py-1rem">
                <Link to={`${process.env.PUBLIC_URL}/asesores-en-cero`}>
                  <button className="btn btn-primary">Asesores en cero</button>
                </Link>{" "}
              </div>
            </>
          )} */}
          {usuario.puede.entrarA.medallero && (
            <div className="text-center col py-1rem">
              <Link to={`${process.env.PUBLIC_URL}/medallero`}>
                <button className="btn btn-primary">Medallas</button>
              </Link>{" "}
            </div>
          )}
          {usuario.puede.entrarA.usuarios && (
            <div className="text-center col py-1rem">
              <Link to={`${process.env.PUBLIC_URL}/usuarios`}>
                <button className="btn btn-primary">Usuarios</button>
              </Link>{" "}
            </div>
          )}
          {usuario.puede.entrarA.cargaDeArchivos && (
            <div className="text-center col py-1rem">
              <Link to={`${process.env.PUBLIC_URL}/carga-de-metas`}>
                <button className="btn btn-primary">Carga de archivos</button>
              </Link>{" "}
            </div>
          )}
        </div>
      </div>
    );

  if (usuario.tipo >= 2)
    return (
      <div className="row flex-column flex-lg-row mt-n1rem5">
        <div className="col-lg-9 col-xl-10 mt-1rem5">
          <div className="row card-group-plus mt-n1rem5">
            <div className="text-center col-lg mt-1rem">
              <Link
                className="text-lead"
                to={`${process.env.PUBLIC_URL}/perfil`}
              >
                <div className="rounded card">
                  <p className="fw-bold">{`${userInfo.nombres} ${userInfo.apPat} ${userInfo.apMat}`}</p>
                  <p className="m-0">{userInfo.numeroEmpleado}</p>
                  <p className="m-0 text-capitalizer">{`${userInfo.nombreTienda}`}</p>
                  <div className="text-center">
                    <button className="btn btn-primary w-50 mt-1rem card-hover">
                      Ir a tu perfil
                    </button>
                  </div>
                </div>
              </Link>
            </div>

            {usuario ? (
              usuario.tipo === 2 ? (
                <div className="col-lg-7 mt-1rem">
                  <div className="text-center rounded card">
                    <Link
                      className="text-lead"
                      to={`${process.env.PUBLIC_URL}/medallero`}
                    >
                      <Estrellas data={data} />
                      <button className="btn btn-primary mt-1rem card-hover">
                        Ir a medallero
                      </button>
                    </Link>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          <div className="row pt-1rem">
            <div className="col">
              <div className="rounded card">
                {data ? (
                  <Link
                    className="text-lead"
                    to={`${process.env.PUBLIC_URL}/estadisticas`}
                  >
                    <Estadisticas
                      datosGrafica={data.datosGrafica}
                      avances={data.avances}
                      usuario={usuario}
                    />
                    <div className="text-center">
                      <button className="btn btn-primary mt-1rem card-hover">
                        Ver tu avance
                      </button>
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-xl-2 mt-1rem5">
          <div className="text-center rounded card circulos-verticales">
            <h6 className="fw-bold mb-1rem">Avances de tu tienda</h6>
            <Circulos
              cadenaComercial={userInfo.cadenaComercial}
              numeroTienda={userInfo.numeroTienda}
              setloading={setloading}
              view={4}
              mes={mes}
              anio={anio}
            />
          </div>
        </div>
      </div>
    );
};

// Componente que muestra la estrella ganada
export const Estrellas = ({ data }) => {
  if (!data) return null;

  if (data.ganaEstrella === "Oro") {
    party.screen();
    return (
      <div className="text-center">
        <h6>
          Si hoy fuera cierre de mes, ganarías una estrella de{" "}
          <span className="text-gold">oro</span>.
        </h6>
        <img src={IconGold} alt="Estrella Dorada" className="mt-1rem5" />
      </div>
    );
  } else if (data.ganaEstrella === "Plata") {
    return (
      <div className="text-center">
        <h6>
          Si hoy fuera cierre de mes, ganarías una estrella de{" "}
          <span className="text-silver">plata</span>.
        </h6>
        <img src={IconSilver} alt="Estrella plateada" className="mt-1rem5" />
      </div>
    );
  } else if (data.ganaEstrella === "Bronce") {
    return (
      <div className="text-center">
        <h6>
          Si hoy fuera cierre de mes, ganarías una estrella de{" "}
          <span className="text-bronze">bronce</span>.
        </h6>
        <img src={IconBronze} alt="Estrella plateada" className="mt-1rem5" />
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <h6>Si hoy fuera cierre de mes, no tendrías ninguna estrella.</h6>
        <img
          src={IconSilverOutlined}
          alt="Estrella bordeada"
          className="mt-1rem5"
        />
      </div>
    );
  }
};

// Gráfica que verá tanto el jefe de crédito como el asesor
export const Estadisticas = ({ datosGrafica, avances }) => {
  return (
    <Container>
      <h4>Tus avances</h4>
      <div className="row my-1rem">
        <div className="text-center col my-1rem">
          <h5>
            {avances.reactivaciones}/{avances.metaReactivaciones}
          </h5>
          <h5 className="mt-rem5">Reactivaciones</h5>
        </div>
        <div className="text-center col my-1rem">
          <h5>
            {avances.migraciones}/{avances.metaMigraciones}
          </h5>
          <h5 className="mt-rem5">Migraciones</h5>
        </div>
        <div className="text-center col my-1rem">
          <h5>
            {isNaN(avances.ippf)
              ? `${avances.ippf}`
              : `${Number(avances.ippf).toFixed(2)}%`}
          </h5>
          <h5 className="mt-rem5">IPPF</h5>
        </div>
        <div className="text-center col my-1rem">
          <h5>{Number(avances.rechazo).toFixed(2)}%</h5>
          <h5 className="mt-rem5">Rechazo Inicial</h5>
        </div>
      </div>
      <div className="d-none d-lg-flex row">
        {/* Reactivaciones */}
        <div className="col-6">
          <Grafica
            labels={datosGrafica.reactivaciones.fecha}
            dataSet={datosGrafica.reactivaciones.avances}
            label="Reactivaciones"
            color="rgba(255, 99, 132, 0.6)"
            colorMedia="rgba(54, 162, 235, 0.6)"
            dataMedia={datosGrafica.reactivaciones.media}
          />
        </div>
        {/* Migraciones */}
        <div className="col-6">
          <Grafica
            labels={datosGrafica.migraciones.fecha}
            dataSet={datosGrafica.migraciones.avances}
            dataMedia={datosGrafica.migraciones.media}
            label="Migraciones"
            color="rgba(54, 162, 235, 0.6)"
            colorMedia="rgba(255, 99, 132, 0.6)"
          />
        </div>
      </div>
    </Container>
  );
};

// Monedero del asesor
export const Monedero = () => {
  return (
    <div className="row">
      <div className="col align-items-center mt-1rem">
        <h4 className="fw-bold">Has ganado</h4>
        <h2 className="text-primary">$543.60</h2>
        <p className="m-0 text-muted">pesos</p>
      </div>
      <div className="col align-items-center mt-1rem">
        <h4 className="fw-bold">Tu premio se depositará en</h4>
        <h2 className="text-primary">5</h2>
        <p className="m-0 text-muted">días</p>
      </div>
    </div>
  );
};

// Gráfica
export const Grafica = ({ labels, dataSet,dataMedia,colorMedia, color, label }) => {
  const [data] = useState({
    chartData: {
      labels: labels,
      datasets: [
        {
          label: label,
          data: dataSet,
          borderColor: color,
          fill: false,
        },
        {
          label: 'Movimientos diarios',
          data: dataMedia,
          borderColor: colorMedia,
          fill: false,
        },
      ],
    },
  });

  const options = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (Number.isInteger(value)) {
                return value;
              }
            },
            stepSize: 1,
          },
        },
      ],
    },
  };

  return <Line data={data.chartData} options={options} />;
};

export default Dashborad;
