// Libraries
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
// Icons
import IconBack from "../../assets/icons/back.svg";
import IconBefore from "../../assets/icons/backArrow.svg";
import IconNext from "../../assets/icons/nextArrow.svg";
import { notification } from "../../helpers/Helpers";
// Components
import Circulos from "./Componentes/Circulos";
import CirculosNomina from "./Componentes/CirculosNomina";
// import CirculosVerticales from "./Componentes/CirculosVerticales";
import DataTable from "./Componentes/DataTable";
// import TablaFiltros from "./Componentes/TablaFiltros";
import PifDataTable from "./Componentes/PifDataTable";

const Estadisticas = ({ setloading, userInfo, usuario }) => {
  const setViewInicial = () => {
    if (usuario.puede.ver.estadisticas.zonas) return 1;
    if (usuario.puede.ver.estadisticas.suzona) return 2;
    if (usuario.puede.ver.estadisticas.sutienda) return 3;
    if (usuario.puede.ver.estadisticas.suyas) return 4;
  };

  const setTituloInicial = () => {
    if (usuario.puede.ver.estadisticas.zonas) return "Ranking nacional";
    if (usuario.puede.ver.estadisticas.suzona)
      return `Ranking de la ${userInfo.zona}`;
    if (usuario.puede.ver.estadisticas.sutienda)
      return `Ranking de ${userInfo.nombreTienda}`;
    if (usuario.puede.ver.estadisticas.suyas) return `Tus avances`;
  };

  const showArrow = () => {
    if (view === 1) {
      return false;
    } else if (view === 2) {
      if (usuario.puede.ver.estadisticas.zonas) {
        return true;
      } else {
        return false;
      }
    } else if (view === 3) {
      if (usuario.puede.ver.estadisticas.suzona) {
        return true;
      } else {
        return false;
      }
    } else if (view === 4) {
      if (usuario.puede.ver.estadisticas.sutienda) {
        return true;
      } else {
        return false;
      }
    }
  };

  // Hook en el que se guarda la fecha
  //const [fecha, setfecha] = useState(null);
  const [mes, setmes] = useState(null);
  const [anio, setanio] = useState(null);
  const [titleFecha, setTitleFecha] = useState(null);
  // Vista que determina qué tabla se va a mostrar
  const [view, setview] = useState(setViewInicial());
  // Titulo
  const [titulo, settitulo] = useState(setTituloInicial());
  const [titleArrays] = useState(["", "", "", "", ""]);
  // Zona a enviar
  const [zona, setzona] = useState(userInfo.zona ? userInfo.zona : null);
  const [division, setDivision] = useState(null);
  const [pifZona, setPifZona] = useState(null);
  // Numero de tienda a enviar
  const [numeroTienda, setnumeroTienda] = useState(
    userInfo.numeroTienda ? userInfo.numeroTienda : null
  );
  // Cadena comercial
  const [cadenaComercial, setCadenaComercial] = useState(
    userInfo.cadenaComercial ? userInfo.cadenaComercial : 1
  );

  // Numero de empleado
  const [numeroEmpleado, setnumeroEmpleado] = useState(
    userInfo.numeroEmpleado ? userInfo.numeroEmpleado : null
  );
  // Hook que determina si la vista es global o por filtros
  const [vistaGlobal] = useState(true);
  // Hook que determina qué categoría estamos viendo en la vista de por filtros
  const [categoria, setcategoria] = useState("Reactivacion");
  // Arrow visible
  const [arrowVisible, setarrowVisible] = useState(showArrow());
  // Variable para obtener la fecha local
  const [date, setDate] = useState(DateTime.now());
  const [dateNow] = useState(DateTime.now());
  // Meta que se quiere visualizar
  const [meta, setMeta] = useState("Oro");

  // Nuevo hook para seleccionar el Tipo de PIF
  const [tipoPif, setTipoPif] = useState("tarjeta_credito");

  titleArrays[view] = titulo;

  // Manejar cambios en el Tipo de PIF
  const handleTipoPifChange = (e) => {
    const value = e.target.value;
    setTipoPif(value);
    if (value === "tarjeta_credito") {
      setCadenaComercial("1");
    } else if (value === "nomina") {
      setCadenaComercial("3");
    } else {
      setCadenaComercial("");
    }
  };

  const handleDate = (op) => {
    if (op === "addition") {
      setDate(date.plus({ months: 1 }));
    } else {
      setDate(date.minus({ months: 1 }));
    }
  };

  useEffect(() => {
    setmes(date.c.month);
    setanio(date.c.year);

    if (userInfo.lastUpdate) {
    const formattedDate = DateTime.fromISO(userInfo.lastUpdate).setLocale('es').toLocaleString(DateTime.DATE_FULL);
    setTitleFecha(formattedDate);
  } else {
    setTitleFecha("No disponible");
  }
  }, [date, userInfo.lastUpdate]);

  useEffect(() => {
    setarrowVisible(showArrow());
    setcategoria("Reactivacion");
  }, [view, showArrow]);

  // Función que maneja el cambio a una vista anterior
  const handleBack = () => {
    settitulo(titleArrays[view - 2]);
    setview(view - 1);
  };

  // Función que maneja el cambio de vista global a vista por zonas
  const handleGlobal = (zona, nombre) => {
    if (
      zona !== userInfo.zona &&
      !userInfo.coordinadorZonas?.includes(zona) &&
      !usuario.puede.ver.estadisticas.otraszonas
    ) {
      notification("No tienes permiso de ver este dato");
    } else {
      const text = `Ranking de la ${nombre}`;
      setzona(zona);
      setview(2);
      titleArrays[view - 1] = titulo;
      settitulo(text);
      titleArrays[view] = text;
    }
  };

  // Función que maneja el cambio de vista de zonas
  const handleZona = (id, tienda) => {
    if (
      id !== userInfo.numeroTienda &&
      !usuario.puede.ver.estadisticas.otrastiendas
    ) {
      notification("No tienes permiso para ver este dato.");
    } else {
      const text = `Ranking de ${tienda}`;
      setnumeroTienda(id);
      setview(3);
      titleArrays[view - 1] = titulo;
      settitulo(text);
      titleArrays[view] = text;
    }
  };

  // Función que maneja el cambio de vista a una tienda
  const handleTienda = (numeroEmpleado, nombreEmpleado) => {
    if (
      numeroEmpleado !== userInfo.numeroEmpleado &&
      !usuario.puede.ver.estadisticas.otrosasesores
    ) {
      notification("No tienes permiso para ver este dato.");
    } else {
      const text = `Avance de ${nombreEmpleado}`;
      setnumeroEmpleado(numeroEmpleado);
      setview(4);
      titleArrays[view - 1] = titulo;
      settitulo(text);
      titleArrays[view] = text;
    }
  };

  const handlePifDivision = (selectedDivision) => {
    if (!usuario.puede.ver.estadisticas.divisiones) {
      notification("No tienes permiso de ver este dato");
    } else {
      setDivision(selectedDivision);
      setview(2);
      const text = `Ranking de la división ${selectedDivision}`;
      titleArrays[view - 1] = titulo;
      settitulo(text);
      titleArrays[view] = text;
    }
  };

  const handlePifZona = (selectedZona) => {
    if (!usuario.puede.ver.estadisticas.otraszonas) {
      notification("No tienes permiso de ver este dato");
    } else {
      setPifZona(selectedZona);
      setview(3);
      const text = `Ranking de la zona ${selectedZona}`;
      titleArrays[view - 1] = titulo;
      settitulo(text);
      titleArrays[view] = text;
    }
  };  

  const handleCadenaComercial = (cadena) => {
    setCadenaComercial(cadena);
  };

  return (
    <div className="row flex-column flex-lg-row mt-n1rem5">
      <div className="col mt-1rem5">
        <div className="text-center rounded card">
          {arrowVisible && (
            <div className="text-left">
              <div onClick={() => handleBack()} className="cursor-pointer">
                <img src={IconBack} alt="Back Arrow" className="back-arrow" />
              </div>
            </div>
          )}
          <h2 className="pb-1rem5">{titulo}</h2>
          <div className="row">
            <div className="col d-none d-md-block"></div>
            <div className="col">
              <p className="mt-0 mb-1rem">{`Actualizado por última vez el: ${titleFecha}`}</p>
            </div>
            <div className="col d-none d-md-block"></div>
          </div>
          {view === 4 && vistaGlobal && (
            <h4 className="text-center mb-1rem">Avances de la tienda</h4>
          )}

          {/* SECTION Filters */}
          <div className="row my-5rem text-center justify-content-center">
            {view === 1 && usuario.esAdmin && (
              <div className="col-md-4 col-lg-3 mb-3">
                <label htmlFor="tipoPif">Tipo de PIF:</label>
                <div className="form-label-group w-100">
                  <select
                    name="tipoPif"
                    id="tipoPif"
                    className="form-control mb-rem5"
                    value={tipoPif}
                    onChange={handleTipoPifChange}
                  >
                    <option value="tarjeta_credito">PIF tarjeta (LPC/DILISA)</option>
                    <option value="nomina">Nómina</option>
                  </select>
                </div>
        
                <label htmlFor="cadenaComercial">Línea de negocio:</label>
                <div className="form-label-group w-100">
                  <select
                    name="cadenaComercial"
                    id="cadenaComercial"
                    className="form-control mb-rem5 mw-50"
                    value={cadenaComercial}
                    onChange={(e) => handleCadenaComercial(e.target.value)}
                  >
                    {tipoPif === "tarjeta_credito" && (
                      <>
                        <option value="1">Liverpool</option>
                        <option value="2">Suburbia</option>
                      </>
                    )}
                    {tipoPif === "nomina" && (
                      <option value="3">Puerto de Liverpool</option>
                    )}
                  </select>
                </div>
              </div>
            )}
            <div className="text-center col col-lg-3 d-flex align-items-center justify-content-center">
              <img
                src={IconBefore}
                alt=""
                className="cursor-pointer card-hover-big"
                data-tip="Cambiar de categoría"
                onClick={() => handleDate("substract")}
              />
              <p className="m-0 px-1rem">
                {date
                  .setLocale("es")
                  .toLocaleString({ year: "numeric", month: "long" })}
              </p>
              <img
                src={IconNext}
                alt=""
                className="cursor-pointer card-hover-big"
                data-tip="Cambiar de categoría"
                onClick={() => handleDate("addition")}
              />
            </div>
            {view <= 4 && tipoPif !== "nomina" && (
              <div className="col col-lg-3 d-flex flex-column align-items-center justify-content-center">
                <label htmlFor="cadenaComercial">Tipo de meta:</label>
                <div className="form-label-group w-100">
                  <select
                    name="Categoria"
                    id="categoria"
                    className="form-control mb-rem5 mw-50"
                    onChange={(e) => setMeta(e.target.value)}
                  >
                    <option
                      value="Oro"
                      selected={meta === "Oro" ? true : false}
                    >
                      Oro
                    </option>
                    <option
                      value="Plata"
                      selected={meta === "Plata" ? true : false}
                    >
                      Plata
                    </option>
                    <option
                      value="Bronce"
                      selected={meta === "Bronce" ? true : false}
                    >
                      Bronce
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
          {/* !SECTION Filters */}

          {/* SECTION Círculos */}
          {tipoPif === "nomina" ? (
          <CirculosNomina
            setloading={setloading}
            view={view}
            division={division}
            pifZona={pifZona}
            mes={mes}
            anio={anio}
            meta={meta}
          />
          ) : (
          <Circulos
            setloading={setloading}
            view={view}
            zona={zona}
            numeroTienda={numeroTienda}
            cadenaComercial={cadenaComercial}
            mes={mes}
            anio={anio}
            meta={meta}
          />
          )}
          {/* !SECTION Círculos */}

          {/* SECTION Textos */}
          {tipoPif !== "nomina" && (
            <div className="text-left row flex-column pt-1rem5 pl-1rem">
              <p className="m-0">
                <span className="text-primary">*</span> El Rank se calcula con base en una ponderación equitativa del 25%
                para reactivaciones, migraciones, IPPF y rechazo inicial.
              </p>
              <p className="m-0">
                <span className="text-primary">**</span> Todos los avances
                (excepto los individuales) se miden respecto a la meta
                seleccionada.
              </p>
              <p className="m-0">
                <span className="text-primary">***</span> El rechazo inicial es el
                único indicador que se mide contra el mes pasado, todos los demás
                se consideran meta vs real del mes en curso.
              </p>
              <p className="m-0">
                <span className="text-primary">****</span> Para ser ganador de la Estrella indicada
                en la columna “si fuera cierre de mes” se requiere el cierre de IPPF.
              </p>
              <p className="m-0">
                <span className="text-primary">*****</span> Las tiendas nuevas no
                tienen una meta asignada, la meta será lo que tengan de avance al
                momento de la consulta.
              </p>
              <p className="m-0">
                <span className="text-primary">******</span> Si encuentras
                diferencias entre el avance y el desglose de crédito y CS, debes
                validar que todo el personal este dado de alta en la plataforma.
              </p>
              <p className="m-0">
                <span className="text-primary">*******</span> Válida que todo tu personal este dado de alta en la plataforma para evitar diferencias.
              </p>
              {cadenaComercial === "1" &&
                <>
                  <div className="row">
                    {/* <!-- Columna 1 --> */}
                    <div className="col">
                      <p className="m-0">
                        <br />
                        CN = Cuentas nuevas.<br />
                        CN CP = Cuentas nuevas credipocket<br />
                        CN CP SF = Cuentas nuevas credipocket self service<br />
                        JC = Jefe de Crédito.<br />
                        SC = Supervisor de Crédito.<br />
                        AC = Asesor de Crédito.<br />
                      </p>
                    </div>

                    {/* <!-- Columna 2 --> */}
                    <div className="col">
                      <p className="m-0">
                        <br />
                        JS = Jefe de Seguros.<br />
                        SS = Supervisor de Seguros.<br />
                        AS = Asesor de Seguros.<br />
                        CO = Cobranza.<br />
                      </p>
                    </div>
                  </div>
                </>
              }
              { cadenaComercial === "2" &&
                <>
                  <div className="row">
                    {/* <!-- Columna 1 --> */}
                    <div className="col">
                      <p className="m-0">
                        <br />
                        CN = Cuenta nueva.<br />
                        LC = Límite de crédito.<br />
                        JC = Jefe de Crédito.<br />
                        SC = Supervisor de Crédito.<br />
                        AC = Asesor de Crédito.<br />
                      </p>
                    </div>
                    {/* <!-- Columna 2 --> */}
                    <div className="col">
                      <p className="m-0">
                        <br/>
                        JS = Jefe de Seguros.<br />
                        SS = Supervisor de Seguros.<br />
                        AS = Asesor de Seguros.<br />
                        CO = Cobranza.<br />
                      </p>
                    </div>
                  </div>
                </>
              }
            </div>
          )}
          {/* !SECTION Textos */}

          {/*Inicio tabla Estadisticas*/}
          <div className="table-responsive scroll-x">
            {/* SECTION Tabla */}
            {vistaGlobal && tipoPif === "tarjeta_credito" && (
              <DataTable
                setloading={setloading}
                view={view}
                zona={zona}
                numeroTienda={numeroTienda}
                numeroEmpleado={numeroEmpleado}
                usuario={usuario}
                cadenaComercial={cadenaComercial}
                handleGlobal={handleGlobal}
                handleZona={handleZona}
                handleTienda={handleTienda}
                userInfo={userInfo}
                titleFecha={titleFecha}
                setTitleFecha={setTitleFecha}
                mes={mes}
                anio={anio}
                meta={meta}
                dateNow={dateNow}
                date={date}
              />
            )}
            {tipoPif === "nomina" && (
              <PifDataTable
                setloading={setloading}
                view={view}
                division={division}
                pifZona={pifZona}
                handlePifDivision={handlePifDivision}
                handlePifZona={handlePifZona}
                zona={zona}
                numeroTienda={numeroTienda}
                numeroEmpleado={numeroEmpleado}
                usuario={usuario}
                userInfo={userInfo}
                titleFecha={titleFecha}
                setTitleFecha={setTitleFecha}
                mes={mes}
                anio={anio}
                meta={meta}
                dateNow={dateNow}
                date={date}
              />
            )}
            {/* !SECTION Tabla */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Estadisticas;
