import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { Table, ButtonGroup, ToggleButton } from 'react-bootstrap';

const PorAsesor = () => {
  const [selectedTeam, setSelectedTeam] = useState('credito');
  const [selectedMonth, setSelectedMonth] = useState('');

  const teamOptions = [
    { label: 'Crédito', value: 'credito' },
    { label: 'Seguros', value: 'seguros' }
  ];

  const monthOptions = [
    { label: 'Ene', value: 'ene' },
    { label: 'Feb', value: 'feb' },
    { label: 'Mar', value: 'mar' },
    { label: 'Abr', value: 'abr' },
    { label: 'May', value: 'may' },
    { label: 'Jun', value: 'jun' },
    { label: 'Jul', value: 'jul' },
    { label: 'Ago', value: 'ago' },
    { label: 'Sep', value: 'sep' },
    { label: 'Oct', value: 'oct' },
    { label: 'Nov', value: 'nov' },
    { label: 'Dic', value: 'dic' }
  ];

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth();
    setSelectedMonth(monthOptions[currentMonthIndex].value);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Zona',
        accessor: 'zona'
      },
      {
        Header: 'Crédito',
        accessor: 'credito'
      },
      {
        Header: 'Seguros',
        accessor: 'seguros'
      },
      {
        Header: 'Vol React',
        accessor: 'volReact'
      },
      {
        Header: '% Alcance Meta',
        accessor: 'alcanceMeta'
      }
    ],
    []
  );

  const data = React.useMemo(
    () => [
      { zona: 'Zona 6', credito: '91%', seguros: '9%', volReact: '8,048', alcanceMeta: '80%' },
      { zona: 'Zona 3', credito: '87%', seguros: '13%', volReact: '11,108', alcanceMeta: '87%' },
      // Agregar más filas de datos según sea necesario
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  return (
    <>
      <div className="row flex-column mt-n1rem5 gap-3">
        <div className="col-lg-2 col-md-3 mt-1rem5">
          <div className="rounded card p-3">
            {/* Slicer para seleccionar el equipo */}
            <div className="mb-3 text-left">
              <h5 className="mb-3">Equipo</h5>
              <ButtonGroup vertical toggle className="d-flex flex-column gap-3">
                {teamOptions.map((team, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant={selectedTeam === team.value ? "primary" : "outline-primary"}
                    name="team"
                    value={team.value}
                    checked={selectedTeam === team.value}
                    onChange={(e) => setSelectedTeam(e.currentTarget.value)}
                  >
                    <span style={{ color: selectedTeam === team.value ? '#ffffff' : '#000000' }}>{team.label}</span>
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="col-lg-10 col-md-9 mt-1rem5">
          <div className="rounded card p-3 mb-5" style={{ overflowX: 'auto' }}>
            {/* Tabla de Rangos por mes crédito y seguros */}
            <Table striped bordered hover {...getTableProps()} className="mt-3">
              <thead>
                  <tr>
                      <th rowSpan='' colSpan='2' className="align-middle even">
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Enero-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Febrero-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Marzo-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Abril-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Mayo-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Junio-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Julio-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Agosto-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Septiembre-24
                      </th>
                      <th colSpan='5' scope="colgroup" className="align-middle table-dark">
                          Octubre-24
                      </th>
                  </tr>

                  <tr>
                      <th rowSpan='1' scope="col" className="align-middle sticky-header even-title">
                          Rango alcance meta reactivación
                      </th>
                      <th rowSpan='2' scope="col" className="align-middle sticky-header even">
                          # Ejecutivos
                      </th>
                  </tr>
              </thead>

              <colgroup>
                  <col span="2" />
                  <col span="6" className="even" />
              </colgroup>

              <tbody className="table-bordered">
              </tbody>

              <tfoot>
              </tfoot>
            </Table>
          </div>
        </div>
      </div>
      <div className="row flex-column flex-lg-row mt-n1rem5">
        <div className="col-lg-2 col-md-3 mt-1rem5">
          <div className="rounded card p-3">
            {/* Slicer para seleccionar el mes */}
            <div className="mb-3 text-left">
              <h5 className="mb-3">Mes</h5>
              <ButtonGroup vertical toggle className="d-flex flex-column gap-3">
                {monthOptions.map((month, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant={selectedMonth === month.value ? "secondary" : "outline-secondary"}
                    name="month"
                    value={month.value}
                    checked={selectedMonth === month.value}
                    onChange={(e) => setSelectedMonth(e.currentTarget.value)}
                  >
                    {month.label}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="col-lg-10 col-md-9 mt-1rem5">
          <div className="rounded card p-3" style={{ overflowX: 'auto' }}>
            {/* Encabezado sobre la tabla */}
            <div className="mb-3 text-center">
              <h4 style={{ color: '#6a0dad' }}>Share Contribución Reactivaciones</h4>
            </div>
            {/* Tabla de Contribución Reactivaciones y Vol Reactivaciones */}
            <Table striped bordered hover {...getTableProps()} className="mt-3">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()} style={{ backgroundColor: '#f78c1f', color: '#ffffff' }}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PorAsesor;
